import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../common.service';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss'],
})
export class ReturnsComponent implements OnInit {
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.imageTitle = '../../../assets/titles/returns.jpg';
      this.commonService.isHomePage = false;
    }, 100);
  }
}

import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-ljoh-online',
  templateUrl: './ljoh-online.component.html',
  styleUrls: ['./ljoh-online.component.scss'],
})
export class LjohOnlineComponent implements OnInit {
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.imageTitle = '../../../assets/titles/custom.jpg';
      this.commonService.isHomePage = false;
    }, 100);
  }
}

<div class="row">
  <div class="col-12">
    So you want to email in your pictures and have your own head in a jar? Or
    your ex’s? More than happy to make your grim wish come true. Here’s a bit of
    info: Each Little Jar of Horror contains a laminated, full wrap around hi
    resolution image cylinder that we create from your images. It is placed into
    a 8"x11" plastic jar that is hand painted in the motif of your choice,
    either Fetid Green or Fresh Red. Each jar comes with color tablets that you
    place in the jar when filling it up with water. As the water level rises,
    you will see that the design achieves the desired depth and distortion to
    give it the illusion of being 3-D. Sounds creepy as you wanted? Then let’s
    get started!
  </div>
</div>
<div class="row title">
  <div class="col-12 text-center">Custom jars are just $37 each!</div>
</div>
<div class="row">
  <div class="col-12">
    Just follow the tips below, and email close up files of the face, back of
    head and each side to us at
    <a href="mailto:custom@littlejarsofhorror.com">
      custom@littlejarsofhorror.com</a
    >. If any of the files aren’t quite what we need, don’t worry, we won’t
    shrug and callously send you something back that sucks, we will simply
    contact you back right away and tell you to retake whatever picture we need
    retaken. We will work with you and get you the perfect milky eyed floater.
    (MMMMM, milky. All you will need is a cookie to go with all that milky eyed
    floaty-ness) <br />&nbsp;<br />
    To get started please purchase a custom jar using the PayPal button below,
    choosing either the SHIPPING OR PICK UP option. Use the dropdown option to
    select whether you'd like the Fresh Red or Fetid Green jar style.
    <br />&nbsp;<br />
    SHIPPING: If you would like your order shipped to you, we charge $10 to ship
    anywhere in the US per jar via USPS. For shipping, please make sure to use
    the "Add to Cart" button below the "Shipped" label. Shipping will then be
    added within the Paypal cart. Shipping discounts are only available when
    jars are purchased in groups of 6, but you will have to contact us to have
    us create a custom invoice for you with the exact shipping amount from
    Fed-Ex Quick Ship.
    <br />&nbsp;<br />
    PICK-UP: If you are in the Chicagoland area, you can arrange local pickup in
    St. Charles, which is 45 min West of O'Hare. If you would like to pick this
    option, please make sure to use the "Add to Cart" button below the "Pickup"
    label
    <br />&nbsp;<br />
    Once you have successfully purchased the jar through PayPal you will receive
    a confirmation email from PayPal. We will also receive a similar email..
    Once we have what we need, we will get started on your jar and let you know
    when it is shipping.
  </div>
</div>
<div class="row paragraph">
  <div class="col-1">&nbsp;</div>
  <div class="col-2 text-center">
    <img src="../../../assets/buttons/palid-paul.png" alt="" />
  </div>
  <div class="col-4 text-center">
    <div class="row paragraph">
      <div class="col-12 bolded">Shipped</div>
    </div>
    <div class="row paragraph">
      <div class="col-12 text-center">
        <form
          target="paypal"
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="HQDY85X96V3XG" />
          <input type="hidden" name="on0" value="Jar Style" />
          <div class="paragraph">Jar Style</div>
          <div class="paragraph">
            <select name="os0">
              <option value="Fresh Red">Fresh Red $37.00 USD</option>
              <option value="Fetid Green">Fetid Green $37.00 USD</option>
              <option value="Frosted">Frosted $42.00 USD</option>
            </select>
          </div>
          <input type="hidden" name="currency_code" value="USD" />
          <div class="paragraph">
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif"
              name="submit"
              alt="PayPal - The safer, easier way to pay online!"
            />
          </div>
          <img
            alt=""
            src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </div>
    </div>
  </div>
  <div class="col-4 text-center">
    <div class="row paragraph">
      <div class="col-12 bolded">Pickup</div>
    </div>
    <div class="row paragraph">
      <div class="col-12 text-center">
        <form
          target="paypal"
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="F6VRCVYRBJA2E" />
          <input type="hidden" name="on0" value="Jar Style" />
          <div class="paragraph">Jar Style</div>
          <div class="paragraph">
            <select name="os0">
              <option value="Fresh Red">Fresh Red $37.00 USD</option>
              <option value="Fetid Green">Fetid Green $37.00 USD</option>
              <option value="Frosted">Frosted $42.00 USD</option>
            </select>
          </div>
          <input type="hidden" name="currency_code" value="USD" />
          <div class="paragraph">
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif"
              name="submit"
              alt="PayPal - The safer, easier way to pay online!"
            />
          </div>
          <img
            alt=""
            src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </div>
    </div>
  </div>
  <div class="col-1">&nbsp;</div>
</div>
<div class="row paragraph">
  <div class="col-12">
    Tips for a successful milky eyed floater:
    <br />&nbsp;<br />
    The important thing to remember when you get in front of the camera yourself
    or are pointing the camera at your friend or family member is that we need
    your head straight….nose pointing towards the lens. I know it is hard to do
    a creepy pose without moving other parts of your body in mock manifest of
    the monster you are trying to be, but you can’t. We have to have your head
    lined up straight and symmnetrical for the image to be able to be used.
  </div>
</div>
<div class="row paragraph">
  <div class="col-3 text-center">
    <img
      src="../../../assets/media/Front.jpg"
      alt="Front of head"
      style="border: 2px solid red"
    />
  </div>
  <div class="col-3 text-center">
    <img
      src="../../../assets/media/Side-of-head-1.jpg"
      alt="Left side of head"
      style="border: 2px solid red"
    />
  </div>
  <div class="col-3 text-center">
    <img
      src="../../../assets/media/Side-of-head-2.jpg"
      alt="Right side of head"
      style="border: 2px solid red"
    />
  </div>
  <div class="col-3 text-center">
    <img
      src="../../../assets/media/Back-of-Head.jpg"
      alt="Back of head"
      style="border: 2px solid red"
    />
  </div>
</div>
<div class="row paragraph">
  <div class="col-12">
    The best floaters have the mouth open halfway, tongue just slightly out or
    lolling out to the side….eyes wide, maybe glancing to the side, up or even
    crossed. Just basically look dead and uh, floating. You can also give the
    impression you went out with more character, and go with a scared
    expression, crazed look or evil grimace. If the mouth is open for the front
    picture, then the same expression has to be matched when the side pictures
    are taken, so that the jaw lines up. This is all important for us in post
    production, as it helps us get the jawlines, cheekbones, and hairlines to
    line up when we are digitally stitching them together. Once we have
    everything pulled together, we will make your eyes opaque and milky.
    <br />&nbsp;<br />
    Practice makes perfect, right? Go to a mirror and work on that weird
    expression…..its totally okay, we give you permission.
  </div>
</div>

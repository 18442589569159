<div class="row">
  <div class="col-12">
    Little Jars of Horror is committed to protecting your privacy. We use the
    information we collect to assist us in processing orders or replying to your
    inquiries. Please read on for more details about our privacy policy.
    <br />&nbsp;<br />
    <b>The Information We Collect</b>
    <br />&nbsp;<br />
    Little Jars of Horror collects from you the necessary information to process
    orders for our products or answer your online questions.
    <br />&nbsp;<br />
    <b>Spam Email and Use of Email Addresses</b>
    <br />&nbsp;<br />
    We make every effort to minimize the amount of email correspondence you
    receive from us. We do not share or sell your email address to any third
    parties.
    <br />&nbsp;<br />
    <b>Site Security</b>
    <br />&nbsp;<br />
    To ensure your security and order satisfaction, we employ industry leading
    security by partnering with Paypal to offer safe and secure credit card
    transactions for our customers.
    <br />&nbsp;<br />
    Paypal manages the complex routing of sensitive customer information. The
    company adheres to strict industry standards for payment processing,
    including:
    <ul>
      <li>
        128-bit Secure Sockets Layer (SSL) technology for secure Internet
        Protocol (IP) transactions.
      </li>
      <li>
        Industry leading encryption hardware and software methods and security
        protocols to protect customer information.
      </li>
      <li>
        Compliance with the Payment Card Industry (PCI) Data Security Standard.
      </li>
    </ul>
    For additional information regarding the privacy of your sensitive
    cardholder data, please read the Paypal Security assurance page at:
    https://www.paypal.com/us/webapps/mpp/paypal-safety-and-security
    <br />&nbsp;<br />
    <b>Use of Cookies</b>
    <br />&nbsp;<br />
    Little Jars of Horror uses cookies to enhance your shopping and browsing
    experience and keep track of your order information. The cookies we use DO
    NOT store any personal information such as your e-mail address, street
    address or phone number.
    <br />&nbsp;<br />
    <b>Do you still have questions?</b>
    <br />&nbsp;<br />
    If you have additional questions or wish to further discuss details of our
    privacy policy, please feel free to email us at
    <a href="mailto:info@littlejarsofhorror.com">info@littlejarsofhorror.com</a
    >.
  </div>
</div>

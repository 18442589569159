import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Component({
  selector: 'faq-qa',
  templateUrl: './faq-qa.component.html',
  styleUrls: ['./faq-qa.component.scss'],
})
export class FaqQaComponent implements OnInit {
  @Input()
  question!: string;

  @Input()
  answer!: string;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}
}

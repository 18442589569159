<table style="width: 100%">
  <tr>
    <td rowspan="2" style="width: 50%"></td>
    <td style="text-align: left">
      <a href="#" routerLink="/"
        ><img
          src="../../../assets/main/logo_small.jpg"
          alt="Click to return to Home Page"
      /></a>
    </td>
    <td style="text-align: center">
      <img [src]="commonService.imageTitle" alt="" />
    </td>
    <td rowspan="2" style="width: 50%"></td>
  </tr>
</table>

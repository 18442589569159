<div class="row">
  <div class="col-12">
    We love Halloween., as well as all things horror and pop culture. A lot. And
    I’m thinking since you are on this website checking things out that you
    probably feel the same way. But why heads in a jar? Random, right? A few
    things sort came together perfectly to inspire Little Jars Of Horror. Due to
    the weird economy, I closed my children’s themed photography studio after 11
    creatively rewarding years. I was looking for something else inventive to do
    with my fairly specific skill set. I just couldn’t decide what I wanted to
    do, and as any family that suddenly has a reduction in income is quick to
    realize, the “when” factor becomes a point of concern. Frustrated at not
    knowing what my next path should or even could be, I decided to throw my
    energy into something positive... working on props for our upcoming haunted
    house. Our family does an annual home haunt and we always try to come up
    with a new room each year. My husband and I decided to crank out our
    family’s heads in jars for one of the new rooms (I photograph... he
    Photoshops) and they turned out awesome. In fact, everyone we showed them to
    said that we really needed to sell them. As my friend said, “Where else can
    someone get their own head popped in a jar?” It was true. Where could you
    get something so custom and novel? I did a search online and there simply
    wasn’t anything like it out there being offered as a retail item.
    <br />&nbsp;<br />
    So it looked like I found what I was looking for... a new fun & creative
    outlet that provided a hard to find niche service that used all the same
    skills as before. So my very first booth was at Scare LA to give it a try,
    to see if anyone might be interested. We were busy doing custom jars from
    the moment it opened, so it was encouraging for sure. Then I walked around
    Scare LA and asked some of the crazy-talented FX Make Up Artists and
    Prop/Mask Companies to let me photograph their creations. They were total
    works of art, and made for the perfect addition to the company.....allowing
    customers the choice between the completely one of a kind custom jars or the
    seriously creepy stock jars.
    <br />&nbsp;<br />
    We have lots of new artist creations coming on board…follow our social media
    pages to know when each batch launches on the website.
    <br />&nbsp;<br />
    Customers seem really thrilled we exist, and are loving the product. We hope
    that the business continues to take off.&nbsp; We are setting our sights on
    getting proper retail packaging , taking them to all the Halloween shows and
    getting them in stores for 2017.&nbsp; Maybe we will be a suck-cess story
    like the wretched &quot;Elf on the Shelf&quot;.....perhaps I should rename
    the company&nbsp; &quot;Head in the Bed&quot; or &quot;Dead in the
    Shed&quot; something along the likes. Thoughts on rhyming horror? Haha
    .&nbsp;
    <br />&nbsp;<br />
    -Dove
  </div>
</div>

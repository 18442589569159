<div class="row">
  <div class="col-12">
    The use of this site is governed by the policies, terms and conditions set
    forth below. Please read them carefully. <br />&nbsp;<br />
    <b
      >Your use of this site indicates your acceptance of these terms and
      conditions.</b
    >
    <br />&nbsp;<br />
    Your placement of an order indicates your acceptance of these terms and
    conditions. Little Jars Of Horror reserves the right to change and amend
    this terms and conditions at any time without notice to you. Such changes
    and amendments shall become effective immediately upon the posting of same
    to the littlejarsofhorror.com website.
    <br />&nbsp;<br />
    <b>Copyright Information</b>
    <br />&nbsp;<br />
    This site is owned and operated by Little Jars of Horror. Unless otherwise
    specified, all materials appearing on this site, including the text, site
    design, logos, graphics, icons, and images, as well as the selection,
    assembly and arrangement thereof, are the sole property of Little Jars of
    Horror.
    <br />&nbsp;<br />
    All software used on the site is the sole property of Little Jars of Horror,
    or those supplying the software. You may use the content of this site only
    for the purpose of shopping on this site or placing an order on this site
    and for no other purpose. No materials from this site may be copied,
    reproduced, modified, republished, uploaded, posted, transmitted, or
    distributed in any form or by any means without prior written permission.
    The exception to this is using the provided Pin It button where indicated to
    pin to Pinterest.
    <br />&nbsp;<br />
    All rights not expressly granted herein are reserved. Any unauthorized use
    of the materials appearing on this site may violate copyright, trademark and
    other applicable laws and could result in criminal or civil penalties.
    <br />&nbsp;<br />
    <b>Disclaimers, Warranty and Return Policy</b>
    <br />&nbsp;<br />
    All of Little Jars of Horrors products are returnable within a month of the
    date of delivery provided they are received back in sellable condition,
    which means the acrylic jar cannot be scratched, misshapen or dented. If the
    product was received damaged, then please keep packaging and contact us,
    providing a picture of the damaged product and box so that we can take it up
    with the shipping company while we are processing your replacement.
    <br />&nbsp;<br />
    Photographic picture within the jar is guaranteed so if any issues with
    lamination occur, please email us with a picture and description of the
    damage, and we will replace the insert. KEEP JAR AWAY FROM DIRECT SUNLIGHT
    and EXTREME HEAT. We were told by the jar manufacturer to remind everyone of
    this so that your jar doesn’t become warped.
    <br />&nbsp;<br />
    Please see our specific return policy
    <a href="#" routerLink="/returns">here</a>. <br />&nbsp;<br />
    <b>Typographical Errors</b>
    <br />&nbsp;<br />
    In the event a product is listed at an incorrect price due to typographical
    error or error in pricing information received from our suppliers, Little
    Jars of Horror shall have the right to refuse or cancel any orders placed
    for product(s) listed at the incorrect price.
    <br />&nbsp;<br />
    Little Jars of Horror shall have the right to refuse or cancel any such
    orders whether or not the order has been confirmed and your credit card
    charged. If your credit card has already been charged for the purchase and
    your order is canceled, Little Jars of Horror shall immediately issue a
    credit to your credit card account in the amount of the incorrect price and
    email you to let you know, giving you the option of ordering again.
    <br />&nbsp;<br />
    <b>Compliance with Law and Fraud Protection</b>
    <br />&nbsp;<br />
    We may collect and disclose any information, including personally
    identifiable information, we deem necessary, in our sole discretion, to
    comply with any applicable law, regulation, legal process or governmental
    request. We may collect and possibly share personally identifiable
    information and any other information available to us in order to
    investigate, prevent or take action regarding illegal activities, suspected
    fraud, situations involving potential threats to the physical safety of any
    personal or as otherwise required by law.
    <br />&nbsp;<br />
    <b>Links</b>
    <br />&nbsp;<br />
    This site may contain links to other sites on the Internet that are owned
    and operated by other vendors and social media companies that Little Jars of
    Horror think you might have similarly interesting horror/Halloween content.
    You acknowledge that Little Jars of Horror is not responsible for the
    operation of or content located on or through any such site.
    <br />&nbsp;<br />
    <b>Communications</b>
    <br />&nbsp;<br />
    If you provide Little Jars of Horror with personally identifiable
    information, this information may be used to communicate to you information
    regarding Little Jars of Horror and its products and services. For example,
    we may communicate to you with information regarding promotions, surveys,
    contests, new products or service updates.
    <br />&nbsp;<br />
    If you place an order with Little Jars of Horror, we will use your
    personally identifiable information to confirm your order, prevent and
    detect fraud or abuse, respond to your requests or inquiries, provide you
    with other administrative information regarding your order and provide you
    with any other information we deem helpful or interesting to you.
    <br />&nbsp;<br />
    If you do not want to receive communications from Little Jars of Horror you
    may elect to opt-out of receiving such communications, please email us at
    <a href="mailto:info@littlejarsofhorror.com">info@littlejarsofhorror.com</a>
    with the word “unsubscribe” in the subject header.
  </div>
</div>

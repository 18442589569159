import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.imageTitle = '../../../assets/titles/privacy.jpg';
      this.commonService.isHomePage = false;
    }, 100);
  }
}

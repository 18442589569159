<div id="main">
  <app-header *ngIf="!commonService.isHomePage"></app-header>
  <div class="container main-container">
    <router-outlet></router-outlet>
  </div>
</div>
<div id="small">
  <a href="#" routerLink="/terms">Terms And Conditions</a>&nbsp;|&nbsp;<a
    href="#"
    routerLink="/privacy"
    >Privacy Policy</a
  >&nbsp;|&nbsp;<a href="#" routerLink="/returns">Return Policy</a>
</div>
<div id="copyright">
  Copyright © 2014-{{ year }} Little Jars Of Horror<br />
</div>

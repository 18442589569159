import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public isHomePage = false;
  public imageTitle = '';

  constructor() {}
}

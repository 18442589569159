<div class="row">
  <div class="col-12">
    <b>RETURNING YOUR ORDER</b>
    <br />&nbsp;<br />
    All of Little Jars of Horrors stock products are exchangeable or returnable
    within a month of the date of delivery, provided they are received back in
    sellable condition, which means the acrylic jar cannot be scratched,
    misshapen or dented. If the product was received damaged, then please keep
    packaging and email us, providing a picture of the damaged product and box
    so that we can take it up with the shipping company while we are processing
    your replacement. Due to the personal nature of custom products using your
    image, they are not usually returnable unless there is a mistake or defect,
    but in which case we will work with you to redo the product. If absolutely
    need be, we will have you return the custom jar for credit towards a stock
    jar.
    <br />&nbsp;<br />
    Photographic picture within the jar is absolutely guaranteed so if any
    issues with lamination occur, please email us with a picture and description
    of the damage, and we will replace the insert.
    <br />&nbsp;<br />
    KEEP JAR AWAY FROM DIRECT SUNLIGHT and EXTREME HEAT. We were told by the jar
    manufacturer to remind everyone of this so that your jar doesn’t become
    warped. So no cruising around town with the jar sloshing around on your
    dashboard.
    <br />&nbsp;<br />
    If you would like to return or exchange a product, please email us at
    <a href="mailto:info@littlejarsofhorror.com">info@littlejarsofhorror.com</a>
    with your info and concerns, and we will get back to you with solutions and
    give our address if needed. <br />&nbsp;<br />
    If it is decided that you would like to return a product, when you send it
    back, please use original packing materials, and make sure you retain the
    tracking number for your records. We do not reimburse for return shipping,
    and we are not responsible for returned items lost or damaged while
    shipping.
    <br />&nbsp;<br />
    A refund or customer credit (for exchanges) will be issued within 10
    business days. All refunds will be issued in the original form of payment.
    Usually it only takes Paypal a few days, but please allow up to two weeks
    for the credit to appear on your credit card statement.
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.imageTitle = '../../../assets/titles/faq.jpg';
      this.commonService.isHomePage = false;
    }, 100);
  }
}

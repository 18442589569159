<table style="width: 100%">
  <tr>
    <td rowspan="2" style="width: 40%">&nbsp;</td>
    <td rowspan="2">
      <img src="../assets/main/logo_main.png" alt="Little Jars Of Horror" />
    </td>
    <td style="text-align: right">
      <a href="#" routerLink="/faq">
        <img
          src="../assets/buttons/faq.jpg"
          alt="Click to find out everything you want to know about jars!"
          onmouseover="hover(this,'../assets/buttons/faq_on.jpg');"
          onmouseout="hover(this,'../assets/buttons/faq.jpg');"
        />
      </a>
      <!-- <img
        src="../assets/buttons/faq.jpg"
        alt="Click to find out everything you want to know about jars!"
        onmouseover="hover(this,'../assets/buttons/faq_on.jpg');playSound('sound','../assets/sounds/cleaver.wav');"
        onmouseout="hover(this,'../assets/buttons/faq.jpg');"
      /> -->
    </td>
    <td style="text-align: center">
      <a href="#" routerLink="/why">
        <img
          src="../assets/buttons/why.jpg"
          alt="Click to find out why we do what we do!"
          onmouseover="hover(this,'../assets/buttons/why_on.jpg');"
          onmouseout="hover(this,'../assets/buttons/why.jpg');"
        />
      </a>
      <!-- <img
        src="../assets/buttons/why.jpg"
        alt="Click to find out why we do what we do!"
        onmouseover="hover(this,'../assets/buttons/why_on.jpg');playSound('sound','../assets/sounds/gloves.mp3');"
        onmouseout="hover(this,'../assets/buttons/why.jpg');"
      /> -->
    </td>
    <td style="text-align: left">
      <a href="#" routerLink="/contact">
        <img
          src="../assets/buttons/contact.jpg"
          alt="Click to send us messages"
          onmouseover="hover(this,'../assets/buttons/contact_on.jpg');"
          onmouseout="hover(this,'../assets/buttons/contact.jpg');"
        />
      </a>
      <!-- <img
        src="../assets/buttons/contact.jpg"
        alt="Click to send us messages"
        onmouseover="hover(this,'../assets/buttons/contact_on.jpg');playSound('sound','../assets/sounds/bottle.wav');"
        onmouseout="hover(this,'../assets/buttons/contact.jpg');"
      /> -->
    </td>
    <td rowspan="2" style="width: 40%">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="3">
      <img src="../assets/main/tagline.jpg" alt="" />
    </td>
  </tr>
  <tr>
    <td style="width: 40%">&nbsp;</td>
    <td colspan="4">
      <table style="width: 100%; margin-top: 15px; text-align: center">
        <tr>
          <td><img src="../assets/titles/custom_subtitle.jpg" alt="" /></td>
          <td rowspan="3">
            <table style="width: 100%; text-align: center">
              <tr>
                <td>
                  <a
                    href="http://www.today.com/video/9-ghoulish-decorations-that-will-frighten-your-friends-on-halloween-790859331542"
                    target="_new"
                    ><img src="../assets/buttons/AsSeenOn.png" alt=""
                  /></a>
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="http://www.huffingtonpost.com/entry/donald-trump-novelty-products_us_5769d053e4b065534f482bc1"
                    target="_new"
                    ><img src="../assets/buttons/HuffPost1.png" alt=""
                  /></a>
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="http://www.huffingtonpost.com/entry/2016-weird-presidential-swag_us_57ec00bce4b0c2407cdb2a02"
                    target="_new"
                    ><img src="../assets/buttons/HuffPost2.png" alt=""
                  /></a>
                </td>
              </tr>
            </table>
          </td>
          <td><img src="../assets/titles/stock_subtitle.jpg" alt="" /></td>
        </tr>
        <tr>
          <td>
            <a href="#" routerLink="/ljoh_online">
              <img src="../assets/buttons/custom_jars.jpg" alt="" />
            </a>
          </td>
          <td>
            <a
              href="https://ghoulishmortals.shop/collections/little-jars-of-horror"
              target="_new"
            >
              <img src="../assets/buttons/stock_jars.jpg" alt="" />
            </a>
          </td>
        </tr>
        <tr>
          <td><img src="../assets/titles/custom_tagline.jpg" alt="" /></td>
          <td><img src="../assets/titles/stock_tagline.jpg" alt="" /></td>
        </tr>
      </table>
    </td>
    <td style="width: 40%">&nbsp;</td>
  </tr>
  <tr>
    <td style="width: 40%">&nbsp;</td>
    <td colspan="4">
      <table style="width: 100%; margin-top: 0px; text-align: center">
        <tr>
          <td>
            <img src="../assets/media/Comments.gif" alt="Customer Comments" />
          </td>
        </tr>
      </table>
    </td>
    <td style="width: 40%">&nbsp;</td>
  </tr>
  <tr>
    <td style="width: 40%">&nbsp;</td>
    <td colspan="4">
      <table style="width: 100%; margin-top: 10px; text-align: center">
        <tr>
          <td>
            <a href="https://www.facebook.com/LittleJarsOfHorror" target="_new"
              ><img src="../assets/buttons/facebook.jpg" alt=""
            /></a>
          </td>
          <td>
            <a href="http://www.pinterest.com/jarsofhorror/pins/" target="_new"
              ><img src="../assets/buttons/pinterest.jpg" alt=""
            /></a>
          </td>
          <td>
            <a href="https://twitter.com/JarsOfHorror" target="_new"
              ><img src="../assets/buttons/twitter.jpg" alt=""
            /></a>
          </td>
          <td>
            <a href="http://instagram.com/littlejarsofhorror" target="_new"
              ><img src="../assets/buttons/instagram.jpg" alt=""
            /></a>
          </td>
        </tr>
      </table>
    </td>
    <td style="width: 40%">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="6"><span id="sound"></span>&nbsp;</td>
  </tr>
</table>

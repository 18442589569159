import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { LjohOnlineComponent } from './pages/ljoh-online/ljoh-online.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

import { ReturnsComponent } from './pages/returns/returns.component';
import { TermsComponent } from './pages/terms/terms.component';
import { WhyComponent } from './pages/why/why.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'ljoh_online', component: LjohOnlineComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'returns', component: ReturnsComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'why', component: WhyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="row">
  <div class="col-12">
    Have a question for us? Check out the FAQ section below. If your question
    isn't answered then please use our
    <a href="#" routerLink="/contact">Contact</a> form to ask your question.
    <faq-qa
      question="How big are the jars?"
      answer="The jars are approximately 11” H x 8” W."
    ></faq-qa>
    <faq-qa
      question="I would like to order several jars….do you offer wholesale pricing?"
      answer="If you would like to order 24 jars for your store or your haunt, we can offer wholesale pricing of $3 off of the regular pricing per jar. If you order more than that, then we can discuss a bigger discount."
    ></faq-qa>
    <faq-qa
      question="How do I store the jars when it isn’t Halloween time?"
      answer="You can simply drain the water, and then leave the lid off. You must store the jar out of direct sunlight and away from extreme heat. Rather than storing in your garage, we recommend storing it inside your home, perhaps under your kitchen sink or maybe in the cabinet over your refrigerator.  When it is time to fill it again, simply refill with cold water and pop some children’s bathtub tints in."
    ></faq-qa>
    <faq-qa
      question="We would like you to photograph a group of us for custom jars….will you come to us if we have enough people?"
      answer="If you have more than 15 people that would like to prepay for their custom jar, we would be happy to schedule a time to come to your location. Each custom jar takes 10-20 minutes to create after the image is taken, so we would likely just photograph everyone, and then deliver them all at a later date. However, we can discuss possibly bringing our post production equipment with if you don’t mind us on site for that many hours."
    ></faq-qa>
  </div>
</div>

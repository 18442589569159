import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.imageTitle = '../../../assets/titles/contact.jpg';
      this.commonService.isHomePage = false;
    }, 100);
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaqComponent } from './pages/faq/faq.component';
import { WhyComponent } from './pages/why/why.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LjohOnlineComponent } from './pages/ljoh-online/ljoh-online.component';
import { StockComponent } from './pages/stock/stock.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FaqQaComponent } from './components/faq-qa/faq-qa.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    WhyComponent,
    ContactComponent,
    LjohOnlineComponent,
    StockComponent,
    TermsComponent,
    PrivacyComponent,
    ReturnsComponent,
    HomeComponent,
    HeaderComponent,
    FaqQaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
